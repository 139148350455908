/* The model to pull and format data related to the 'Flood Forecast Model' */
import { Object_To_URL_PARAMS } from "@/scripts/url_helpers"
import axios from "axios"
// const API_ROOT = process.env.VUE_APP_API_ROOT
const FORECAST_LIMIT = 72

/**
 * agnostic helper method to pull all pages of specified url based on paged 'next' urls
 * and return the collection of items
 * django api collection helper
 *
 * @param {string} url url to a API collection
 * @param {Function} callback callback method for each page
 * @param {Array} collection the collection of values passed along with the requests
 * @returns promise that resolves to a collection of requested items
 */

// agnostic helper method to pull all pages of specified url based on paged 'next' urls
//      django api collection helper
function request_all_pages(url, callback, collection = []) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        if (response.status !== 200) {
          throw `Error fetching ${url}: ${response.status} ${response.statusText}`
        }
        collection = collection.concat(response.data.results)

        // if there's a next page of data perform next call
        if (response.data.next) {
          if (callback) {
            callback(collection)
          }
          request_all_pages(response.data.next, callback, collection)
            .then(resolve)
            .catch(reject)
        } else {
          resolve(collection)
        }
      })
      .catch(reject)
  })
}

const FORECAST_MODES = {
  current: {
    label: "Current"
  },
  historical: {
    label: "Historical Events"
  }
}

const state = {
  feature_set: null,
  feature: null,
  use_annotations: false,

  forecast_mode: Object.keys(FORECAST_MODES)[0],
  watersheds: [],
  watershed: null,

  forecast_data: {
    current: {
      // watersheds: [],
      forecasts: [],
      // watershed: null,
      forecast: null
    },
    historical: {
      events: [],
      // watersheds: [],
      forecasts: [],

      event: null,
      // watershed: null,
      forecast: null
    }
  }

  /* 
        TODO: i feel like this would be better served as a separate 'state' object
                but not sure how to accomplish this currently without a separate model
                which would repeat a lot...
        historical event properties 
    */
}

const getters = {
  isModelSelected: model => {
    // return model.forecast_data[model.forecast_mode].watershed != null
    return model.watershed != null
  },

  getSelectedForecastMode: model => model.forecast_mode,
  getForecastModes: () => FORECAST_MODES,

  useAnnotations: model => model.use_annotations,
  getSelectedWatershed: model =>
    // model.forecast_data[model.forecast_mode].watershed,
    model.watershed,
  getSelectedForecast: model =>
    model.forecast_data[model.forecast_mode].forecast,

  getSelectedFloodModelFeature: model => model.feature,

  allWatersheds: model =>
    // model.forecast_data[model.forecast_mode].watersheds,
    model.watersheds,
  allForecasts: model => model.forecast_data[model.forecast_mode].forecasts,

  getFeatureSet: model => model.feature_set,
  getFeatureSet_GeoJSON: model => {
    return {
      type: "FeatureCollection",
      features: model.feature_set
    }
  },

  getSelectedHistoricalEvent: model =>
    model.forecast_data[model.forecast_mode].event,
  getHistoricalEvents: model => model.forecast_data[model.forecast_mode].events
}

const actions = {
  setForecastMode({ commit }, forecast_mode) {
    // commit("updateHistoricalEvent", null)
    // commit("updateWatershed", null)
    // commit("updateForecast", null)
    // commit("updateFeature", null)
    // commit("updateForecasts", [])

    if (Object.keys(FORECAST_MODES).includes(forecast_mode)) {
      commit("updateForecastMode", forecast_mode)
    }
  },

  setCurrentWatershed({ commit, state }, updated_model) {
    // console.log(
    //   `old model: ${state.watershed?.id} new model: ${updated_model?.id}`
    // )
    commit("updateWatershed", updated_model)
    // clear derived children
    if (updated_model == null || updated_model.id != state.watershed.id) {
      commit("updateWatershed", null)
      commit("updateForecast", null)
      commit("updateForecasts", [])
      commit("updateFeature", null)
      commit("updateHistoricalEvent", null)
      commit("updateHistoricalEvents", [])
    }
  },

  setCurrentForecast({ commit }, updated_forecast) {
    if (updated_forecast == null) {
      commit("updateForecast", null)
      return
    }

    commit("updateForecast", updated_forecast)
  },

  setCurrentFloodModelFeature({ commit }, updated_feature) {
    commit("updateFeature", updated_feature)
  },

  setAnnotationUse({ commit }, annotation_use) {
    commit("updateAnnotationUse", annotation_use)
  },

  setSelectedHistoricalEvent({ commit, state }, historical_event = null) {
    // if (!("event" in state.forecast_data[state.getSelectedForecastMode]))
    //     return
    // console.log(`mutation setting HE to ${historical_event}`)
    commit("updateHistoricalEvent", historical_event)
    commit("updateForecast", null)
    // clear derived children
    if (historical_event == null) {
      // commit("updateWatershed", null)
      commit("updateForecast", null)
      commit("updateFeature", null)
      commit("updateForecasts", [])
    } else {
      let event_forecasts =
        state.forecast_data["historical"].events.find(
          event => event.name == historical_event
        ).forecasts ?? []
      commit("updateForecasts", event_forecasts)
    }
  },

  async fetchWatersheds({ commit }) {
    // const response = await axios.get(`${API_ROOT}/forecast_model/`)
    const response = await axios.get(`/requestData/forecast_model.json`)
    // console.log("fetchWatersheds:", response)

    // commit the mutation
    commit("updateWatersheds", response.data.results)
  },

  async fetchForecasts({ commit }, watershed_id) {
    // console.log("fetching FORE")
    // clear forecast_collection if model is deselected
    if (watershed_id == null) {
      commit("updateForecasts", [])
      return
    }

    let params = {
      forecast_model_id: watershed_id,
      limit: FORECAST_LIMIT
    }

    // perform fetch loop
    // let url = `${API_ROOT}/forecast/?${Object_To_URL_PARAMS(params)}`
    // const fetched_forecasts = await axios.get(url).catch(err => {
    //   console.warn(`Failed to fetch forecast list: ${err}`)
    //   return []
    // })

    let allForecasts = await axios.get("/requestData/forecasts.json") // these are pre-filtered to what is currently available
    // console.log("forecasts", allForecasts, watershed_id, filteredForecasts)

    // commit("updateForecasts", fetched_forecasts.data.results)
    commit("updateForecasts", allForecasts.data.results)
  },

  async fetchFeatureSet({ commit }, watershed_id) {
    // clear forecasts if model is deselected
    if (watershed_id == null) {
      commit("updateFeatureSet", null)
      return
    }

    let params = {
      forecast_model_id: watershed_id
    }

    // let url = `${API_ROOT}/cross_section/?${Object_To_URL_PARAMS(params)}`

    let url = "/requestData/cross_section.json"

    const feature_set = await request_all_pages(url)
      .then(fetched_feature_collections => {
        let merged_feature_collection = []

        // if there's more than one page of features, we need to merge them into a
        // single layer
        fetched_feature_collections.forEach(feature_collection => {
          merged_feature_collection = merged_feature_collection.concat(
            feature_collection.features.filter(
              // f => f.properties.include_on_map === true
              f =>
                f.properties.include_on_map === true &&
                f.properties.forecast_model == watershed_id
            )
          )
        })

        return merged_feature_collection
      })
      .catch(err => {
        console.warn(`Failed to load cross sections: ${err}`)
        return null
      })

    commit("updateFeatureSet", feature_set)
  },

  async fetchForecast({ commit }, forecast_id) {
    if (forecast_id == null) {
      commit("updateForecast", null)
      return
    }

    // let fetched_forecast = await axios
    //   .get(`${API_ROOT}/forecast/${forecast_id}`)
    //   .catch(err => {
    //     console.warn(`Failed to fetch forecast '${forecast_id}': ${err}`)
    //     return null
    //   })
    let fetched_forecast = await axios
      .get(`/requestData/forecasts/${forecast_id}.json`)
      .catch(err => {
        console.warn(`Failed to fetch forecast '${forecast_id}': ${err}`)
        return null
      })

    // fetched_forecast.data.results.filter(
    //   f => (f.id = forecast_id)
    // )

    // commit("updateForecast", fetched_forecast.data)
    commit("updateForecast", fetched_forecast.data)
  },

  async fetchHistoricalEvents({ commit }, forecast_model_id = null) {
    // console.log("fetching HE")

    // let url = `${API_ROOT}/historical_event/`
    let url = `/requestData/historical_event.json`

    if (forecast_model_id != null) {
      console.log("FORECAST MODEL ID IN HISTORICAL REQUEST", forecast_model_id)

      // console.log("adding to url HE")
      // console.log(forecast_model_id)
      let params = {
        forecast_model_id: forecast_model_id
      }
      url.concat(`?${Object_To_URL_PARAMS(params)}`)
    }

    // let url = `${API_ROOT}/historical_event/`
    // let url = `${API_ROOT}/historical_event/?${Object_To_URL_PARAMS(
    //     params
    // )}`
    const fetched_events = await request_all_pages(url)
      .then(events => {
        // console.log(events)
        return events
      })
      .catch(err => {
        console.warn(`Failed to load historical events: ${err}`)
        return null
      })

    commit("updateHistoricalEvents", fetched_events)
  }
}

const mutations = {
  updateForecastMode: (state, new_val) => (state.forecast_mode = new_val),

  updateWatershed: (state, new_val) =>
    // (state.forecast_data[state.forecast_mode].watershed = new_val),
    (state.watershed = new_val),
  updateForecast: (state, new_val) =>
    (state.forecast_data[state.forecast_mode].forecast = new_val),

  updateFeature: (state, new_val) => (state.feature = new_val),
  updateAnnotationUse: (state, new_val) => (state.use_annotations = new_val),

  updateWatersheds: (state, new_val) =>
    // (state.forecast_data[state.forecast_mode].watersheds = new_val),
    (state.watersheds = new_val),
  updateForecasts: (state, new_val) =>
    (state.forecast_data[state.forecast_mode].forecasts = new_val),
  updateFeatureSet: (state, new_val) => (state.feature_set = new_val),

  /* HISTORICAL EVENT SPECIFIC MUTATIONS */
  updateHistoricalEvents: (state, new_val) =>
    (state.forecast_data["historical"].events = new_val),
  updateHistoricalEvent: (state, new_val) =>
    (state.forecast_data["historical"].event = new_val)
}

export default {
  state,
  getters,
  actions,
  mutations
}
