import axios from "axios"
import { Object_To_URL_PARAMS } from "@/scripts/url_helpers"
import moment from "moment"

/**
 * Model to handle retrieval and serving of data from the NWIS service
 *
 * // instantanious values
 *  https://waterservices.usgs.gov/nwis/iv/?format=json&sites=07386880&parameterCd=00060,00065&siteStatus=all
 *  https://waterservices.usgs.gov/nwis/iv/
 *      ?format=json
 *      &sites=07386880
 *      &parameterCd=00060,00065
 *      &siteStatus=all
 *
 * // daily values
 *      // seems like metadata
 *      https://waterservices.usgs.gov/nwis/dv/?format=json&sites=07386880&siteStatus=all
 *      https://waterservices.usgs.gov/nwis/dv/
 *          ?format=json
 *          &sites=07386880
 *          &siteStatus=all
 *
 *      // tab separated values after header
 *      https://waterdata.usgs.gov/nwis/uv?cb_00060=on&cb_00065=on&format=rdb&site_no=07386880&begin_date=2021-02-03&end_date=2021-02-10
 *      https://waterdata.usgs.gov/nwis/uv
 *          ?cb_00060=on
 *          &cb_00065=on
 *          &format=rdb
 *          &site_no=07386880
 *          &begin_date=2021-02-03
 *          &end_date=2021-02-10
 *
 *      # comments (contains comment containing variable descriptions for parameter numbers)
 *      header row
 *      some weird time row? potentially update interval?
 *      ... value rows ...
 */

function instant_values_parser(raw_data) {
  // get variable description information
  let site_info = {}
  let metrics = []

  // let values = [];    // there is 'value' information included, but i'm not sure what it is... maybe a current value?

  raw_data.value.timeSeries.forEach(series => {
    // add any new site information
    series.sourceInfo.siteCode.forEach(code => {
      if (!site_info[code.value]) {
        site_info[code.value] = {
          name: series.sourceInfo.siteName
        }
      }
    })

    // generate new metric information
    let new_metric = {
      name: series.variable.variableName,
      description: series.variable.variableDescription,
      unit: series.variable.unit.unitCode,
      code: series.variable.variableCode[0].value,
      options: series.variable.options.option,
      values: series.values[0].value,
      noDataValue: series.variable.noDataValue
    }
    metrics.push(new_metric)

    // get the values
  })

  return {
    sites: site_info,
    metrics
    // values,
  }
}

function daily_value_parser(raw_data) {
  /* data to be parsed into keyed parallel arrays
        {
            "header1": [value1, value2, ...]
            "header2": [value1, value2, ...] 
        }

        or objects indexed by parameter values determined by metadata
    */

  // regexes for determining line type
  let error_page = new RegExp(/DOCTYPE/)
  let comment_test = new RegExp(/^ *#/)
  let comment_parameter_header_test = new RegExp(/^ *#/)
  // let comment_test = new RegExp(/^ *#/)

  let raw_lines = raw_data.split("\n")

  // check if the first line is 'DOCTYPE', which indicates an error page
  if (raw_lines.length && raw_lines[0].match(error_page)) {
    return null
  }

  let parameter_lines = []
  let data_lines = []
  let headers = []

  raw_lines.forEach(line => {
    if (line.match(comment_test)) {
      // perform parameter test and push if needed

      return
    }

    if (line) data_lines.push(line.split("\t"))
  })

  if (data_lines.length) {
    // header row should be first data_line
    headers = data_lines.shift()

    // remove next line which holds time values for some reason
    data_lines.shift()
  }

  // format the data into js objects mapped to header fields
  let formatted = data_lines.map(line => {
    let entry = {}
    headers.forEach((header, index) => {
      entry[header] = line[index]
    })
    return entry
  })

  return formatted
}

function daily_value_metadata_parser(raw_data) {
  // raw_data.value.timeSeries // array of what appear to be metrics
  // raw_data.value.timeSeries[].variable   // variable info/description
  // raw_data.value.timeSeries[].values     // a value for the variable (not sure of what, instant value?)

  // get variable description information
  let site_info = {}
  let metrics = []
  // let values = [];    // there is 'value' information included, but i'm not sure what it is... maybe a current value?

  raw_data.value.timeSeries.forEach(series => {
    // add any new site information
    series.sourceInfo.siteCode.forEach(code => {
      if (!site_info[code.value]) {
        site_info[code.value] = {
          name: series.sourceInfo.siteName
        }
      }
    })

    // generate new metric information
    let new_metric = {
      name: series.variable.variableName,
      description: series.variable.variableDescription,
      unit: series.variable.unit.unitCode,
      code: series.variable.variableCode[0].value,
      options: series.variable.options.option
    }
    metrics.push(new_metric)
  })

  return {
    sites: site_info,
    metrics
    // values,
  }
}

const state = {
  selected_site: null,

  // mapping of 'site codes' to relevent 'site' information
  site_data: {},

  // mapping of 'site codes' to relevent metrics in that site
  metrics_data: {},
  metadata: {},
  pulled_data: {} // data will be indexed by 'site id'
}

const getters = {
  /** get all pulled NWIS data */
  allNWISData: model => {
    // somehow need to be able to pass a requested site id to this & potentially perform fetch
    return model.pulled_data
  },

  NWISSites: model => {
    return model.site_data
  },

  NWISSiteMetrics: model => {
    return model.metrics_data
  },

  loadedNWISSites: model => {
    return Object.keys(model.site_data)
  },

  loadedNWISSiteMetrics: model => {
    return Object.keys(model.metrics_data)
  },

  loadedNWISMetricTimes: model => site_id => {
    if (!model.metrics_data[site_id]) return []

    return Object.keys(model.metrics_data[site_id])
  },

  getSelectedNWISSite: model => model.selected_site,
  /** get data for a specific site */
  getNWISData: model => site_id => model.pulled_data[site_id],
  getNWISMetadata: model => site_id => model.metadata[site_id],
  getNWISMetrics: model => site_id => model.metrics_data[site_id],
  getNWISMetricsAtTime: model => ({ site_id, datetime }) => {
    return model.metrics_data[site_id]?.[datetime]
  },

  getNWISSiteData: model => site_id => {
    return model.site_data[site_id]
  },

  getSiteParamCodes: model => site_id => {
    if (!model.metrics_data[site_id]) return null

    return model.metrics_data[site_id].map(metric => metric.code)
  }
}

const actions = {
  setSelectedNWISSite({ commit }, site_id) {
    commit("updateSelectedSite", site_id)
  },

  async fetchInstantValues({ commit }, { site_id, num_hours = 12, datetime }) {
    // this data pull seems to sync up with the Daily pulls
    // though with the added benifit of having the metrics information included instead of separate calls to get meta information

    // reference https://waterservices.usgs.gov/rest/IV-Test-Tool.html
    // sample: https://waterservices.usgs.gov/nwis/iv?format=json&sites=07386880&period=PT12H&parameterCd=00065,00060

    // console.log("getting NWIS-IV")

    let endDT = moment(datetime).toISOString()
    let startDT = moment(datetime)
      .subtract({ hours: num_hours })
      .toISOString()

    let IV_url = "https://waterdata.usgs.gov/nwis/iv"
    let params = {
      format: "json",
      // 'siteStatus': "all",
      sites: site_id,
      // 'parameterCd': "00060,00065,00045",   // discharge, gage height, precip
      parameterCd: "00060,00065", // discharge, gage height, precip

      // either period or dates... so may not be able to use this
      // 'period': `PT${num_hours}H`,

      startDT,
      endDT
    }

    let result = await axios
      .get(`${IV_url}?${Object_To_URL_PARAMS(params)}`)
      .then(res => {
        let parsed_data = instant_values_parser(res.data)
        return parsed_data
      })
      .catch(err => {
        console.warn(`failed to get NWIS instantious values data: ${err}`)
        return null
      })

    if (result.sites) commit("mergeSiteData", { site_id, data: result.sites })
    if (result.metrics)
      commit("updateMetricsData", {
        site_id,
        datetime,
        data: result.metrics
      })
  }

  // async fetchNWISDailyValuesMeta({commit}, site_id="07386880"){
  //     let metadata_url = `https://waterservices.usgs.gov/nwis/dv/`
  //     let params = {
  //         sites: site_id,
  //         format: 'json',
  //         siteStatus: 'all'
  //     }

  //     let result =  await axios.get(`${metadata_url}?${Object_To_URL_PARAMS(params)}`).then((res) =>{
  //         // console.log('got metadata')
  //         // console.log(res.data.value.timeSeries)

  //         let parsed_data = daily_value_metadata_parser(res.data)
  //         return parsed_data

  //     }).catch((err) => {
  //         console.warn(`failed to get NWIS daily values metadata: ${err}`)
  //         return null;
  //     })

  //     if(result.sites) commit('mergeSiteData', {site_id, data: result.sites})
  //     // if(result.metrics) commit('updateNWISMetadata', {site_id, data: result.metrics})
  //     if(result.metrics) commit('updateMetricsData', {site_id, data: result.metrics})
  // },

  // async fetchNWISDailyValues({commit}, {site_id="07386880", date}) {
  //     let DV_url = 'https://waterdata.usgs.gov/nwis/uv'

  //     let begin_date = date
  //     let end_date = date

  //     let params = {
  //         'cb_00060': 'on', // Streamflow/Discharge
  //         'cb_00065': 'on', // Gage Height
  //         'format': 'rdb',
  //         // 'format': 'json',
  //         'site_no': site_id,  // site_id
  //         begin_date, // passed date
  //         end_date,   // passed date +n days
  //     }

  //     let values = await axios.get(`${DV_url}?${Object_To_URL_PARAMS(params)}`).then((res)=> {

  //         let parsed_data = daily_value_parser(res.data)
  //         return parsed_data

  //     }).catch((err) => {
  //         console.warn(`failed to get NWIS daily values: ${err}`)
  //         return null;
  //     })

  //     commit('updateData', {site_id, data: values})

  // }
}

const mutations = {
  // updateNWISMetadata: (state, {site_id, data}) => (state.metadata[site_id] = data),
  updateSelectedSite: (state, site_id) => {
    state.selected_site = site_id
  },
  updateMetricsData: (state, { site_id, datetime, data }) => {
    state.metrics_data[site_id] = state.metrics_data[site_id] ?? {}
    state.metrics_data[site_id][datetime] = data
  },
  mergeSiteData: (state, { data }) => {
    Object.keys(data).forEach(site_id => {
      if (!state.site_data[site_id]) state.site_data[site_id] = data[site_id]
    })
  },
  updateData: (state, { site_id, data }) => (state.pulled_data[site_id] = data)
}

export default {
  state,
  getters,
  actions,
  mutations
}
