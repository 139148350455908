<template>
  <div
    id="forecast_selection_form"
    :class="[getSelectedForecastMode == 'historical' ? 'historical' : '']"
  >
    <div class="forecast_selection_field mode_selection_field">
      <label class="forecast_mode_label" for="forecast_mode">
        Forecast Mode
      </label>
      <div class="forecast_mode_selection_wrapper">
        <!-- <font-awesome icon="clock" /> -->
        <toggle-button
          class="mode_toggle"
          :width="75"
          :labels="{
            unchecked: 'Current',
            checked: 'Past'
          }"
          :color="{
            unchecked: 'rgba(0, 0, 0, 0.2)',
            checked: 'rgba(0, 0, 0, 0.2)'
          }"
          @change="on_forecast_mode_toggle($event)"
        />
        <!-- <font-awesome icon="history" /> -->
      </div>

      <!-- <div class="select_wrap">
                <select
                    class="forecast_mode"
                    name="forecast_mode"
                    @change="on_forecast_mode_changed($event)"
                    :value="
                        this.getSelectedForecastMode == null
                            ? ''
                            : this.getSelectedForecastMode
                    "
                >
                    <option value="">-- Please Select Forecast Mode --</option>
                    <option
                        v-for="(mode, key) in getForecastModes"
                        :key="key"
                        v-bind:value="key"
                        >{{ mode.label }}</option
                    >
                </select>
            </div> -->
    </div>

    <div class="forecast_selection_field">
      <label for="watershed_model"> Watershed Model </label>
      <div class="select_wrap">
        <select
          class="watershed_model"
          name="watershed_model"
          @change="on_watershed_changed($event)"
          :value="
            this.getSelectedWatershed == null
              ? ''
              : this.getSelectedWatershed.id
          "
        >
          <option value="">-- Please Select a Model --</option>
          <option
            v-for="watershed in filteredWatersheds"
            :key="watershed.id"
            v-bind:value="watershed.id"
            >{{ watershed.name }}</option
          >
        </select>
      </div>
    </div>
    <div
      v-if="this.getSelectedForecastMode == 'historical'"
      v-show="this.getSelectedWatershed"
      class="forecast_selection_field"
    >
      <label for="h_event"> Event </label>
      <div class="select_wrap">
        <select
          class="h_event"
          name="h_event"
          @change="on_h_event_changed($event)"
          :value="
            this.getSelectedHistoricalEvent == null
              ? ''
              : this.getSelectedHistoricalEvent
          "
        >
          <option value="">-- Please Select Historical Event --</option>
          <option
            v-for="event in getHistoricalEvents"
            :key="event.name"
            :v-bind:value="event.name"
            >{{ event.name }}</option
          >
        </select>
      </div>
    </div>
    <div class="forecast_selection_field forecast_wrapper">
      <transition
        enter-active-class="forecast_appear"
        leave-active-class="forecast_disappear"
      >
        <div
          class="forecast_content"
          v-show="
            this.getSelectedForecastMode == 'current'
              ? this.getSelectedWatershed
              : this.getSelectedHistoricalEvent

            //&&                            this.allForecasts.length
          "
        >
          <label for="forecast">
            Forecast Time
            <span class="tz" v-if="this.getSelectedWatershed != null"
              >({{ getSelectedWatershed.timezone_name }})</span
            >
          </label>
          <template v-if="allForecasts.length > 0">
            <div class="select_wrap">
              <select
                name="forecast"
                class="forecast"
                @change="on_forecast_changed($event)"
                :disabled="this.getSelectedWatershed == null"
                :value="
                  this.getSelectedForecast == null
                    ? ''
                    : this.getSelectedForecast.id
                "
              >
                <option value="">-- Please Select a Forecast --</option>
                <option
                  v-for="forecast in allForecasts"
                  :key="forecast.id"
                  :value="forecast.id"
                >
                  {{
                    forecast.start_date
                      | moment(
                        "timezone",
                        getSelectedWatershed.timezone_name,
                        "M/D/YY h:mm a"
                      )
                  }}
                </option>
              </select>
            </div>
          </template>
          <template v-else>
            No Forecasts available for this selection
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "forecastSelection",
  data() {
    return {
      SHOW_HIDDEN: false,
      current_watershed_id: null
    }
  },

  computed: {
    ...mapGetters([
      "allWatersheds",
      "allForecasts",

      "getSelectedForecastMode",
      "getForecastModes",

      "getHistoricalEvents",
      "getSelectedHistoricalEvent",

      "getSelectedWatershed",
      "getSelectedForecast",
      "getFeatureSet",
      "getFeatureSet_GeoJSON"
    ]),

    filteredWatersheds() {
      return this.allWatersheds.filter(w => {
        return w.include_on_map || this.SHOW_HIDDEN
      })
    }
  },
  methods: {
    ...mapActions([
      "setStatus",
      "clearStatus",

      "fetchWatersheds",
      "fetchHistoricalEvents",
      "fetchForecasts",
      "fetchForecast",
      "fetchFeatureSet",

      "setForecasts",

      "setCurrentWatershed",
      "setCurrentForecast",

      "setForecastMode",
      "setSelectedHistoricalEvent"
    ]),

    on_watershed_changed(evt) {
      let new_val = evt.target.value || null
      this.set_watershed_selection(new_val)
    },

    on_forecast_mode_changed(evt) {
      let new_val = evt.target.value || null
      this.setForecastMode(new_val)
    },

    on_forecast_changed(evt) {
      let new_val = evt.target.value || null
      this.set_Forecast_selection(new_val)
    },

    on_forecast_mode_toggle(evt) {
      // console.log(evt)
      let new_val = evt.value || false
      this.setForecastMode(new_val ? "historical" : "current")
    },

    on_h_event_changed(evt) {
      let new_val = evt.target.value || null

      // console.log(`in HE changed with ${new_val}`)
      this.setSelectedHistoricalEvent(new_val)
    },

    set_watershed_selection(new_val) {
      let current_watershed = this.filteredWatersheds.find(
        model => model.id == new_val
      )
      // console.log(`in set watershed with ${new_val}`)
      this.current_watershed_id = new_val
      this.setCurrentWatershed(current_watershed || null)
      // this.$nextTick(this.after_watershed_selection)
      this.after_watershed_selection()
      // if (this.getSelectedForecastMode == "current") {
      //     this.setStatus("Fetching Forecast List...")
      //     this.fetchForecasts(new_val).finally(() => {
      //         this.clearStatus()
      //     })
      // } else {
      //     // grab any related historical events
      //     this.setStatus("Fetching Historical Events...")
      //     this.fetchHistoricalEvents(new_val).finally(() => {
      //         this.clearStatus()
      //     })
      // }

      this.setStatus("Fetching Cross Sections...")
      this.fetchFeatureSet(new_val).finally(() => {
        this.clearStatus()
      })
    },

    set_Forecast_selection(new_val) {
      // console.log(`pre fetch ${new_val}`)
      this.setStatus("Fetching Forecast Data...")
      this.fetchForecast(new_val).finally(() => {
        this.clearStatus()
      })
    },

    // set_HistoricalEvent_selection(new_val) {
    //     console.log(`set HEvent to ${new_val}`)
    //     // this.setStatus("Fetching Forecast Data...")
    //     // this.fetchForecast(new_val).finally(() => {
    //     //     this.clearStatus()
    //     // })
    // },

    after_watershed_selection() {
      // console.log(`in after_watershed`)
      if (this.getSelectedWatershed == null) return

      // console.log(`fetching forecasts for ${this.getSelectedWatershed.name}`)
      if (this.getSelectedForecastMode == "current") {
        // if (this.allForecasts.length == 0) {
        // console.log("...fetching forecasts")
        this.setStatus("Fetching Forecast List...")

        this.fetchForecasts(this.getSelectedWatershed.id).finally(() => {
          // console.log(`fetched for ${this.getSelectedWatershed.id}`)
          this.clearStatus()
        })
        // }
      } else {
        if (this.getHistoricalEvents.length == 0) {
          this.setStatus("Fetching Historical Events...")
          this.fetchHistoricalEvents(this.getSelectedWatershed.id).finally(
            () => {
              this.clearStatus()
            }
          )
        }
      }
    }

    // set_ForecastMode_selection(new_val) {
    //     this.setStatus("Fetching Forecast Data...")
    //     this.fetchForecast(new_val).finally(() => {
    //         this.clearStatus()
    //     })
    // },
  },

  created() {
    this.setStatus("Fetching Forecast Models...")

    this.fetchWatersheds()
      .then(() => {
        if (this.filteredWatersheds && this.filteredWatersheds.length > 0) {
          this.setCurrentWatershed(this.filteredWatersheds[0])
        }
      })
      .finally(() => {
        this.clearStatus()
      })
  },

  watch: {
    getSelectedForecastMode: function() {
      if (this.getSelectedWatershed == null) return
      this.after_watershed_selection()
    },
    allWatersheds: function() {
      if (this.allWatersheds && this.allWatersheds.length > 0) {
        // this.setCurrentWatershed(this.allWatersheds[0])
        this.setCurrentWatershed(this.filteredWatersheds[0] || null)
      }
    },

    allForecasts: function() {
      // console.log("in all forcasts watcher")
      // console.log(this.allForecasts)
      if (this.allForecasts && this.allForecasts.length > 0) {
        this.set_Forecast_selection(this.allForecasts[0].id)
      }
    },

    getSelectedWatershed: function() {
      if (this.getSelectedWatershed == null) {
        // if deselected
        this.set_watershed_selection(null)
        // this.setHistoricalEvents([])
      } else if (this.getSelectedWatershed.id == this.current_watershed_id)
        // if the same watershed
        return
      else {
        // if new
        this.set_watershed_selection(this.getSelectedWatershed.id)
      }
    },

    getHistoricalEvents: function() {
      // default to the first available event if there are any
      if (this.getHistoricalEvents && this.getHistoricalEvents.length > 0) {
        // console.log("setting to first")
        // console.log(this.getHistoricalEvents[0].name)

        // TODO: this non-reset will not work with model switches... keep in mind
        if (this.getSelectedHistoricalEvent) return

        // TODO: something weird is causing this to need a next tick
        this.$nextTick(() => {
          this.setSelectedHistoricalEvent(this.getHistoricalEvents[0].name)
        })
      }
    }
    // getSelectedHistoricalEvent: function() {
    // if (this.getSelectedWatershed == null)
    //     this.set_watershed_selection(null)
    // else if (this.getSelectedWatershed.id == this.current_watershed_id)
    //     return
    // else this.set_watershed_selection(this.getSelectedWatershed.id)
    // if (this.getSelectedHistoricalEvent == null) {
    // } else {
    // }
    // },

    // getSelectedForecast: function() {
    // this.set_Forecast_selection(this.getSelectedForecast.id || null)
    // },
  }
}
</script>

<style lang="scss" scoped>
.forecast_appear {
  animation: $anim_fadein;
  // animation-duration: $transition_speed_quick;
}

.forecast_disappear {
  animation: $anim_fadeout;
}

#forecast_selection_form {
  --form-background: var(--app-primary-color);

  &.historical {
    --form-background: var(--app-primary-color-alt);
  }

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;

  background: var(--form-background);

  color: #fff;

  transition: background-color $transition_speed;
  .forecast_selection_field {
    flex-grow: 1;
    // flex-basis: 0;
    margin: 0.5em;
    transition: all $transition_speed;
    display: flex;
    flex-direction: column;

    .tz {
      font-size: 0.75rem;
    }

    &.mode_selection_field {
      // width: 175px;
      width: 120px;
      flex-grow: 0;

      .forecast_mode_selection_wrapper {
        flex-grow: 1;
        .mode_toggle {
          margin: 0.5em;
        }
      }
    }
  }
}

// .forecast_wrapper {
//     .forecast_content {

//     }

// }

label {
  font-weight: 600;
  margin-bottom: 0.3em;
  display: block;
  flex-grow: 1;
}

.select_wrap {
  position: relative;
  overflow: hidden;
  height: 50px;
  position: relative;
  display: block;
  // background-color: $trans_darken;
  border: 1px solid $trans_darken;
  border-radius: 0.75em;

  cursor: pointer;

  transition: all $transition_speed;

  &:hover {
    border-color: $trans_lighten;
  }

  &::after {
    content: "";
    position: absolute;
    right: 10px;

    height: 100%;
    width: 18px;
    background-image: url("../assets/chevron-arrow-down.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    transition: all $transition_speed;
  }

  select {
    outline: none;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    padding: 10px;
    padding-right: 30px;
    border: 0;
    font-size: 1em;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    color: inherit;
    font-family: $header_font;

    cursor: pointer;

    option {
      color: black;
    }
  }
}

@include phone-only {
  #forecast_selection_form {
    .forecast_selection_field {
      font-size: 0.75em;

      .tz {
        font-size: 0.75rem;
      }
    }
  }
}

// desktop i believe?
// @include desktop_only {
//     #forecast_selection_form{
//         flex-direction: column;

//         .forecast_selection_field{
//             flex-grow: 0;

//         }
//     }

// }
</style>
