<template>
  <div class="chart_wrapper">
    <div :class="['chart_container', { has_tools: this.tools }]">
      <div class="chart_container_inner">
        <div class="chart_title" v-if="this.title">
          {{ this.title }}
        </div>
        <div class="chart_subtitle" v-if="this.subtitle != ''">
          {{ this.subtitle }}
        </div>

        <apexchart
          class="chart"
          width="100%"
          :height="this.height"
          :options="this.options"
          :series="this.series"
        ></apexchart>
      </div>

      <template v-if="this.tools">
        <div class="chart_toolbox">
          <div
            v-for="(tool, index) in this.tools"
            class="toolbox_icon_wrapper"
            :key="index"
            :title="tool.title"
            @click="tool.callback"
          >
            <font-awesome
              v-if="tool.icon"
              class="toolbox_icon"
              :icon="tool.icon"
            />
            <div v-else>{{ tool.title }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import _merge from "lodash/merge" // use lodash to merge object configs (apex is supposed to do this, but doesn't...)
import VueApexCharts from "vue-apexcharts"

// window.DEBUG_CHART = FLOOD_MODEL_DATA_PARSER;

// series colors for chart data

export default {
  name: "chart",
  components: {
    apexchart: VueApexCharts
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    height: {
      type: Number,
      default: 260
    },
    options: {
      type: Object
    },
    series: {
      type: Array
    },
    tools: {
      type: Array
    }
  }
  // data: function() {
  //     return {}
  // },

  // computed: {
  //     ...mapGetters([]),
  // },

  // methods: {
  //     ...mapActions([]),
  // },

  // created() {},
  // destroyed() {},
  // watch: {}
}
</script>

<style lang="scss" scoped>
// Chart Handle
$handle_size: 50px;

$handle_icon_default: #fff;
$handle_icon_disabled: #ccc;
$handle_icon_active: $background_dark;

$handle_back_default: $background_dark;
$handle_back_disabled: #777;
$handle_back_active: red;

// toolbox
$toolbox_height: 46px;
$tool_icon_size: 1.25em;
$tool_icon_padding: 0.25em;
$tool_icon_default: #cfcfcf;
$tool_icon_active: $background_dark;
$tool_icon_hover: $background_dark;

$tool_wrap_default: $background_dark;
$tool_wrap_active: #cfcfcf;
$tool_wrap_hover: #4ab6af;

$chart_container_width: $sidebar_width;
$chart_container_min_width: $sidebar_min_width;
// $chart_width: calc($sidebar_width - 2em); // accounting for padding
$chart_height: 570px;
$chart_padding_horiz: 0em;
$chart_padding_vert: 0em;

/***********************************
    SHARED STYLING 
 ***********************************/

.chart_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #fff;

  transition: all $transition_speed;

  .chart_container {
    transition: all $transition_speed ease;
    position: relative;
    width: $chart_container_width;
    min-width: $chart_container_min_width;

    &.has_tools {
      // reserved space for toolbox
      padding-bottom: $toolbox_height;
    }

    .chart_title {
      font-family: $header_font;
      font-weight: 600;
      text-align: center;
    }
    .chart_subtitle {
      font-size: 0.8em;
      font-style: italic;
      text-align: center;
    }

    .chart_container_inner {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: $chart_padding_vert $chart_padding_horiz;
      padding-right: 1em;

      .chart {
        // width: $chart_width;

        // max-width: 800px;

        .apexcharts-canvas {
          width: 800px !important;
        }
      }
    }

    .chart_toolbox {
      position: absolute;
      bottom: 0;
      width: 100%;

      display: flex;
      flex-direction: row;

      padding: 0.5em;
      background: $trans_darken;

      height: $toolbox_height;

      .toolbox_icon_wrapper {
        cursor: pointer;

        padding: $tool_icon_padding;
        border-radius: $tool_icon_padding;
        background: $tool_wrap_default;
        transition: all $transition_speed;

        .toolbox_icon {
          color: $tool_icon_default;
          height: $tool_icon_size;
          width: $tool_icon_size;
        }

        &.active {
          background: $tool_wrap_active;
          .toolbox_icon {
            color: $tool_icon_active;
          }
        }

        &:hover {
          background: $tool_wrap_hover;
          .toolbox_icon {
            color: $tool_icon_hover;
          }
        }
      }
    }
  }
}

/***********************************
    MOBILE ONLY STYLING
 ***********************************/
@include phone-only {
  .chart_wrapper {
    .chart_container {
      width: 100%;
      min-width: unset;
      max-height: 100%;

      .chart_container_inner {
        .chart {
          width: 800px;
          max-width: 100%;
          max-height: $chart_height;
        }
      }
    }
  }
}

/***********************************
    DESKTOP ONLY STYLING
 ***********************************/

@include desktop-only {
  .chart_wrapper {
    flex-direction: row;

    .chart_container {
      height: 100%;
      width: $chart_container_width;
      min-width: $chart_container_min_width;

      .chart_container_inner {
        // height:100%;
        // width: 100%;
        // .chart {}
      }
    }
  }
}
</style>
